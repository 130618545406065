'use client';
import { MynewsdeskLogo } from '@mnd-frontend/ui';
import { usePathname } from 'next/navigation';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import styled, { css } from 'styled-components';
import {
  FooterBottomMenu,
  FooterIcons,
  FooterMenu,
  shouldIgnoreMenuRender,
  SocialMediaLinks,
} from '../../lib/menus';
import { track } from '../../lib/tracker';
import { formatUrl } from '../../utils/formatUrl';

const Wrapper = styled.footer`
  position: relative;
  background: var(--color-deep-purple);
  padding: var(--section-padding) var(--site-padding)
    calc(var(--spacing-medium) + min(80vw, 25rem) * 0.7748592871);

  @media (min-width: 768px) {
    padding-bottom: var(--spacing-medium);
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
`;

const linkStyle = css`
  text-decoration: none;
  color: var(--color-white);
  transition: color 0.25s ease;
  display: block;
  &:hover {
    color: var(--color-primary);
  }
`;

const LinksWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--grid-row-gap, var(--grid-gap, 3rem)) var(--grid-column-gap, var(--grid-gap, 3rem));
  row-gap: var(--spacing-large);
  margin-top: var(--spacing-large);
  margin-bottom: var(--spacing-large);

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: calc(var(--section-padding) * 2);
  }

  @media (min-width: 1080px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const LinksColumn = styled.div`
  display: flex;
  flex-direction: column;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      :not(last-child) {
        margin-bottom: var(--spacing-x-small);
      }
    }
  }
`;

const ColumnHeader = styled.a`
  color: var(--heading-color);
  font-style: var(--body-font-style, normal);
  font-size: 15px;
  font-weight: var(--body-font-weight, normal);
  font-weight: var(--font-weight-extra-bold);
  letter-spacing: var(--body-letter-spacing, 0);
  letter-spacing: 0.06em;
  line-height: var(--body-line-height, 1.6);
  text-transform: uppercase;
  margin-bottom: var(--spacing-small);

  &:after {
    background: var(--color-white);
    content: '';
    display: block;
    height: 4px;
    margin-top: var(--spacing-x-small);
    opacity: 0.15;
    width: 100%;
  }
`;

const ColumnItem = styled.li`
  font-size: 15px;
  font-weight: normal;
  color: var(--color-white);
`;

const ColumnLink = styled.a`
  ${linkStyle}
  display: inline-block;
`;

const AwardsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-medium);
  list-style: none;
  margin-top: var(--spacing-medium);

  img {
    width: 56px;
    height: auto;

    @media (min-width: 768px) {
      width: 80px;
    }
  }

  @media (min-width: 768px) {
    justify-content: start;
    margin: 0;
  }
`;

const BottomLinksContainer = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  flex-direction: column;
  gap: 3px;
  padding: 0;
  margin: 0 0 var(--spacing-medium) 0;

  li {
    a {
      ${linkStyle}
    }
  }

  @media (min-width: 768px) {
    justify-content: start;
    flex-direction: row;
    gap: 28px;
    align-items: center;
    margin: var(--spacing-medium) 0 0 0;
  }
`;

const SocialMediaLinksContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: var(--spacing-medium);

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const SocialMediaLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: var(--color-white);
  opacity: 0.3;
  transition: opacity var(--anim-speed) var(--easing);

  svg {
    color: var(--color-deep-purple);
  }

  &:hover {
    opacity: 1;
  }
`;

const BottomItems = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const CopyrightText = styled.p`
  font-size: var(--font-size-small);
  color: var(--color-white);
  opacity: 0.3;
  margin: var(--spacing-medium) 0;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const ImgWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: min(80vw, 480px);
  display: flex;
  align-items: flex-end;
`;

const SrOnly = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const Footer = ({
  copyrightText,
  footerLinks,
  footerBottomLinks,
  footerIcons,
  socialMediaLinks,
}: {
  copyrightText: string;
  footerLinks?: FooterMenu;
  footerBottomLinks?: FooterBottomMenu;
  footerIcons?: FooterIcons;
  socialMediaLinks?: SocialMediaLinks;
}) => {
  const pathname = usePathname();
  if (shouldIgnoreMenuRender(pathname)) {
    return null;
  }
  const socialMediaLinksWithIcons = socialMediaLinks?.map(link => {
    if (link?.url?.includes('facebook')) {
      return {
        ...link,
        icon: <FaFacebookF />,
      };
    } else if (link?.url?.includes('instagram')) {
      return {
        ...link,
        icon: <FaInstagram />,
      };
    } else if (link?.url?.includes('linkedin')) {
      return {
        ...link,
        icon: <FaLinkedinIn />,
      };
    } else if (link?.url?.includes('twitter')) {
      return {
        ...link,
        icon: <FaTwitter />,
      };
    } else {
      return {
        ...link,
        icon: null,
      };
    }
  });
  return (
    <Wrapper>
      <Content>
        <MynewsdeskLogo width="237" />
        <LinksWrapper>
          {footerLinks?.map(linksHeader => {
            const isTopLevelMenu = linksHeader.parentDatabaseId === 0;
            if (!isTopLevelMenu) return null;
            return (
              linksHeader.label && (
                <LinksColumn key={linksHeader.id}>
                  <ColumnHeader
                    href={formatUrl(linksHeader?.path)}
                    target={linksHeader.target || ''}
                    onClick={() => {
                      track({
                        event: 'footer_navigation',
                        traits: {
                          event_info: {
                            navigation_selection: linksHeader.label ?? '',
                            navigation_category: linksHeader.label ?? '',
                            user_interaction: 'footer_navigation_click',
                          },
                        },
                      });
                    }}
                  >
                    {linksHeader.label}
                  </ColumnHeader>
                  <ul>
                    {linksHeader.childItems?.nodes.map(
                      link =>
                        link.path &&
                        link.label && (
                          <ColumnItem key={link.id}>
                            <ColumnLink
                              href={formatUrl(link.path)}
                              target={link.target || ''}
                              onClick={() => {
                                track({
                                  event: 'footer_navigation',
                                  traits: {
                                    event_info: {
                                      navigation_selection: link.label ?? '',
                                      navigation_category: linksHeader.label ?? '',
                                      user_interaction: 'footer_navigation_click',
                                    },
                                  },
                                });
                              }}
                            >
                              {link.label}
                            </ColumnLink>
                          </ColumnItem>
                        ),
                    )}
                  </ul>
                </LinksColumn>
              )
            );
          })}
        </LinksWrapper>
        <BottomItems>
          <div>
            <AwardsContainer>
              {footerIcons?.map(
                (award, index) =>
                  award &&
                  award.url &&
                  award.image?.node.sourceUrl && (
                    <a
                      key={index}
                      href={formatUrl(award.url)}
                      target="_blank"
                      onClick={() => {
                        track({
                          event: 'footer_navigation',
                          traits: {
                            event_info: {
                              navigation_selection: `award-image-${index + 1}`,
                              navigation_category: 'award',
                              user_interaction: 'footer_navigation_click',
                            },
                          },
                        });
                      }}
                    >
                      {/* eslint-disable-next-line @next/next/no-img-element */}
                      <img src={award.image?.node.sourceUrl} alt="award" width="80" height="104" />
                    </a>
                  ),
              )}
            </AwardsContainer>
            <SocialMediaLinksContainer>
              {socialMediaLinksWithIcons?.map(
                (link, i) =>
                  link &&
                  link.url && (
                    <SocialMediaLink
                      key={`${i}-${link.url}`}
                      href={formatUrl(link.url)}
                      target="_blank"
                      onClick={() => {
                        track({
                          event: 'footer_navigation',
                          traits: {
                            event_info: {
                              navigation_selection: link.name ?? link.url ?? '',
                              navigation_category: 'social',
                              user_interaction: 'footer_navigation_click',
                            },
                          },
                        });
                      }}
                    >
                      {link.icon}
                      <SrOnly>{link.name}</SrOnly>
                    </SocialMediaLink>
                  ),
              )}
            </SocialMediaLinksContainer>
          </div>
          <BottomLinksContainer>
            {footerBottomLinks?.map(
              link =>
                link.path &&
                link.label && (
                  <ColumnItem key={link.id}>
                    <a
                      href={formatUrl(link.path)}
                      target={link.target || ''}
                      onClick={() => {
                        track({
                          event: 'footer_navigation',
                          traits: {
                            event_info: {
                              navigation_selection: link.label ?? '',
                              navigation_category: 'bottom',
                              user_interaction: 'footer_navigation_click',
                            },
                          },
                        });
                      }}
                    >
                      {link.label}
                    </a>
                  </ColumnItem>
                ),
            )}
          </BottomLinksContainer>
        </BottomItems>
        <CopyrightText>
          <>
            &copy; Mynewsdesk {new Date().getFullYear()}. {copyrightText}
          </>
        </CopyrightText>
      </Content>
      <ImgWrapper>
        <svg xmlns="http://www.w3.org/2000/svg" fill="#ff4473" viewBox="0 0 533 413">
          <path d="M101.603 462V193.786c0-11.27.309-22.799 4.061-33.567 3.753-10.768 9.784-20.601 17.685-28.831 7.9-8.23 17.484-14.662 28.098-18.86 10.613-4.198 22.008-6.061 33.409-5.465 56.844 0 79.671 33.751 79.671 85.829v268.214H372.62V192.892c0-48.949 26.856-86.276 80.343-85.829 67.139 0 80.343 33.751 80.343 85.829v268.214H632v-309.34C632 47.8328 557.476.00124157 450.054.00124157 426.452-.0870398 403.07 4.53342 381.28 13.5915c-21.79 9.058-41.549 22.3713-58.118 39.1585C294.516 18.9998 249.085.00124157 181.946.00124157 94.2181.00124157 0 48.9503 0 151.766V462h101.603Z"></path>
        </svg>
      </ImgWrapper>
    </Wrapper>
  );
};

export default Footer;
